<template>
	<div class="view pa24" >
		<div style="font-size: 20px;color: #51CDCB;margin-bottom: 15px;">可用余额：{{money}}</div>
		<div style="display: flex;flex-wrap: wrap;"> 
			<el-button type="primary" class="mr10" @click="add">+ 添加</el-button>
		</div>
		<commonTable :paginationVisible="false" :tableData="tableData">
			<template v-slot:table>
				<el-table-column align="center" width="50px">
					<template slot-scope="scope">
						{{scope.row.index}}
					</template>
				</el-table-column>
				<el-table-column align="center" label="银行名称">
					<template slot-scope="scope">
						<el-input clearable v-model="scope.row.bank_name"></el-input>
					</template>
				</el-table-column>
				<el-table-column align="center" label="支行名称">
					<template slot-scope="scope">
						<el-input clearable v-model="scope.row.subbranch"></el-input>
					</template>
				</el-table-column>
				<el-table-column align="center" label="银行卡号">
					<template slot-scope="scope">
						<el-input clearable v-model="scope.row.cardnumber"></el-input>
					</template>
				</el-table-column>
				<el-table-column align="center" label="开户名">
					<template slot-scope="scope">
						<el-input clearable v-model="scope.row.name"></el-input>
					</template>
				</el-table-column>
				<el-table-column align="center" label="金额">
					<template slot-scope="scope">
						<el-input clearable v-model="scope.row.money"></el-input>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						 <el-button type="danger" plain @click="del(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</template>
		</commonTable>
		<div class="mt20">
			<div class="d-flex">
				<div class="mr10">
					<span class="mr10">谷歌验证码</span>
					<el-input clearable class="w220 mr10" v-model="code" placeholder="请输入谷歌验证码" />
				</div>
				<div class="mr10">
					<span class="mr10">支付密码</span>
					<el-input clearable class="w220 mr10" v-model="password" placeholder="请输入支付密码" />
				</div>
			</div>
			<el-button class="mt10" type="primary" @click="determine">提交</el-button>
		</div>
	</div>
</template>

<script>
	import commonTable from "@/components/common/commonTable";
	import {
		mapState
	} from 'vuex';
	import {
		getBalance,
		apply,
	} from "@/api/settlement";
	let ac;
	export default {
		name: 'windowSet',
		data() {
			return {
				password:'',
				code:'',
				tableData: [],
				money:''
			};
		},
		components: {
			commonTable,
		},
		mounted() {
			this.getInfo()
		},

		methods: {
			getInfo(){
				getBalance()
					.then((res) => {
						console.log(res)
						this.money = res.data.dfmoney
					})
					.catch((err) => {
						this.$message.error(err.msg);
					});
			},
			add(){
				this.tableData.push({})
				for (let i in this.tableData) {
					this.tableData[i]['index'] = Number(i) + 1
				}
			},
			del(row){
				for (let i in this.tableData) {
					if ( this.tableData[i].index == row.index ) {
						this.tableData.splice(i,1)
					}
				}
			},
			/**@method 添加 */
			determine() {
				if ( this.tableData.length == 0 ) {
					this.$message.error('请添加数据')
					return
				}
				if ( !this.password) {
					this.$message.error('请输入密码')
					return
				}
				let ajax = {
					google:this.code,
					pay_password:this.password,
					df:this.tableData
				}
				apply(ajax)
					.then((res) => {
						if (res.code == 1) {
              this.tableData = []
              this.password = ''
              this.code = ''
              this.$message.success(res.msg);
            }else {
              this.$message.error(res.msg);
            }
					})
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>